<template>
  <div class="edit-personalResume">
    <el-form ref="form" :model="form.data" label-width="180px" :rules="form.rules">
      <el-col :span="12">
        <el-form-item label="是否法律工作者：" prop="sfflgzz">
          <el-select v-model="form.data.sfflgzz" placeholder="请选择是否法律工作者">
            <el-option
              v-for="item in form.options.sfflgzz.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否公务员/事业编：" prop="sfzbry">
          <el-select v-model="form.data.sfzbry" placeholder="请选择是否公务员/事业编">
            <el-option
              v-for="item in form.options.sfzbry.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="家庭及主要社会关系：">
          <el-input
            v-model="form.data.jtjzyshgx"
            type="textarea"
            :autosize="{minRows:3,maxRows:10}"
            placeholder="请输入家庭及主要社会关系："
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="何时何地受过何种奖励：">
          <el-input
            v-model="form.data.sddjl"
            type="textarea"
            :autosize="{minRows:3,maxRows:10}"
            placeholder="请输入何时何地受过何种奖励："
          ></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'personalResume',
  props: { Dictionary: Object, info: Object },
  components: {},
  data() {
    return {
      form: {
        data: {
          sfflgzz: '', //是否法律工作者
          sfzbry: '', //是否公务员/事业单位在编人员
          jtjzyshgx: '', //家庭及主要社会关系
          sddjl: '', //受到的奖励
        },
        options: {
          sfflgzz: {
            childrens: [],
          },
          sfzbry: {
            childrens: [],
          },
        },
        rules: {
          sfflgzz: { required: true, message: '请选择是否法律工作者', trigger: 'change' },
          sfzbry: { required: true, message: '请选择是否公务员/事业单位在编人员', trigger: 'change' },
        },
      },
    };
  },
  methods: {
    submit(submit) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (submit) {
            this.$emit('changeInfo', this.form.data);
            this.$emit('changeComponent', 'upload', '上传文件', 4);
          } else {
            this.$emit('changeInfo', this.form.data);
            this.$emit('canChangeComponent', true);
          }
        } else {
          this.$emit('canChangeComponent', false);
          return false;
        }
      });
    },
    toggle(info) {
      this.form.options = this.Dictionary;
      let { sfflgzz, sfzbry, jtjzyshgx, sddjl } = info;
      this.form.data = { sfflgzz, sfzbry, jtjzyshgx, sddjl };
      this.$refs.form.clearValidate()
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.edit-personalResume {
  display: flex;
  flex-direction: column;
  .info {
    margin-top: 50px;
    font-size: 14px;
    color: #0079fe;
    span {
      color: #f56c6c;
    }
  }
  .nextStep {
    text-align: center;
  }
}
::v-deep .el-date-editor {
  width: 100%;
}
.el-select {
  width: 100%;
}
</style>
